import apiManager from './apiManager'
import store from '../store/index'
import router from '../router'

export default {
  login(
    values,
    onSuccess = null,
    onError = null,
    utm1,
    utm2,
    utm3,
    utm4,
    game
  ) {
    apiManager
      .login(values, utm1, utm2, utm3, utm4, game)
      .then((response) => {
        store.commit('account/_set', { k: 'data', v: response.data.data })

        //resfresh api token from user
        apiManager.refreshApiToken()
        // execute success callback from login page
        if (onSuccess) {
          onSuccess()
        }
      })
      .catch(() => {
        if (onError) {
          onError()
        }
      })
  },
  logout() {
    store.dispatch('_deleteAlls')
    router.push({ name: 'Login' })
  }
}
