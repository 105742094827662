<template>
  <Transition name="slide-fade">
    <div
      @click="changeOpen(true)"
      v-if="!open"
      class="block md:hidden bg-blue p-2 rounded-l-xl text-white tewt-4xl w-12 h-fit z-50 absolute -right-4 mt-6"
    >
      <img src="/img/plus.png" alt="voir plus" />
    </div>
  </Transition>
  <Transition name="slide-fade">
    <div
      @click="changeOpen(false)"
      v-if="open"
      style="z-index: 9999"
      class="block md:hidden bg-blue p-2 rounded-l-xl text-white tewt-4xl w-36 h-fit z-50 absolute -right-4 mt-6"
    >
      <div
        class="block md:hidden bg-blue p-2 rounded-l-xl text-white tewt-4xl w-12 h-fit z-50 absolute -left-9 top-0"
      >
        <img src="/img/plus.png" alt="voir plus" />
      </div>
      <div class="ml-6 flex flex-col mt-4 md:mt-0 w-fit pt-16">
        <div class="flex flex-col mb-2 absolute -top-6 right-3">
          <div
            class="flex justify-center text-center bg-white rounded-full px-4 p-2 pb-2 text-2xl text-blue w-28 font-semibold whitespace-nowrap title-app"
          >
            <p
              class="m-auto w-1/2 text-orange font-bold pt-1"
              style="font-size: 2.25rem !important"
            >
              {{ findedWords }}
            </p>
            <p class="m-auto pt-1 w-1/2">/ {{ words.length }}</p>
          </div>
          <p class="text-md font-bold text-white mt-2 whitespace-nowrap">
            {{ type }}
          </p>
          <p class="text-md font-bold text-white whitespace-nowrap">trouvés</p>
        </div>
        <TransitionGroup name="list">
          <div
            class="rounded-full md:p-1 px-2 pt-2 md:pt-3 text-sm font-bold w-fit flex my-1"
            :class="styleWord(word.done)"
            v-for="word in isRandom()"
            v-bind:key="word"
          >
            <p
              class=""
              :class="
                isFinished
                  ? 'text-blue font-bold text-lg relative'
                  : 'font-bold text-lg relative'
              "
              style="bottom: 0.3rem"
            >
              {{
                word.wordList
                  ? word.wordList.charAt(0).toUpperCase()
                  : word.word.charAt(0).toUpperCase()
              }}
            </p>
            <p :class="isFinished ? 'text-orange' : ''">
              {{ word.wordList ? word.wordList.slice(1) : word.word.slice(1) }}
            </p>
          </div>
        </TransitionGroup>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  props: {
    type: [String],
    open: [Boolean],
    words: [Array],
    randomWords: [Array],
    findedWords: [Number],
    isFinished: [Boolean]
  },
  emits: ['changeOpen'],
  methods: {
    changeOpen(value) {
      this.$emit('changeOpen', value)
    },
    isRandom() {
      if (this.isFinished) {
        return this.words
      }
      return this.randomWords
    },
    styleWord(isDone) {
      if (this.isFinished) {
        return 'bg-white text-orange'
      }
      if (isDone) {
        return 'bg-white text-orange'
      }
      return 'bg-blue-mobile text-white'
    }
  }
}
</script>
