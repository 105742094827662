import axios from 'axios'
import store from '../store/index'

const baseURL =
  process.env.NODE_ENV === 'production'
    ? process.env.VUE_APP_API_URL + '/api'
    : 'http://127.0.0.1:8000/api'

let api_token =
  store.state.account.data != null ? store.state.account.data.api_token : ''

const api = axios.create({
  baseURL,
  withCredentials: false,
  headers: { Authorization: 'Bearer ' + api_token }
})

export default {
  refreshApiToken() {
    api.defaults.headers['Authorization'] =
      'Bearer ' + store.state.account.data.api_token
  },
  login(values, utm1, utm2, utm3, utm4, game) {
    return axios('login', {
      baseURL,
      method: 'post',
      data: { values, utm1, utm2, utm3, utm4, game }
    })
  },
  sendScore(data) {
    return axios('send-score', {
      baseURL,
      method: 'post',
      data: { data }
    })
  }
}
