<script setup></script>

<template>
  <div class="relative w-full h-full">
    <div class="mb-4 flex justify-start md:justify-between z-50">
      <a
        @click="logoClickable ? '' : $event.preventDefault()"
        target="_blank"
        :class="logoClickable ? 'cursor-pointer' : 'cursor-default'"
        href="https://assurancevie.secours-catholique.org/"
      >
        <img
          class="w-20 md:w-28 z-50"
          src="/img/logo-secours.svg"
          alt="Logo secours catholique"
        />
      </a>

      <img
        class="app-logo w-48 h-[50%] md:w-64 mx-5"
        src="/img/logo-game.svg"
        alt="Logo mot mêlés de la fraternité"
      />

      <div class="hidden md:block">
        <p class="text-white w-full text-center font-semibold mb-1">
          Suivez nos actions
        </p>
        <div class="flex bg-white rounded-full p-4 px-6 items-end">
          <a
            href="https://twitter.com/caritasfrance"
            target="_blank"
            class="mx-3"
          >
            <img
              class="h-5 cursor-pointer"
              src="/img/twitter.svg"
              alt="logo twitter"
            />
          </a>
          <!-- <a
            href="https://www.linkedin.com/company/secours-catholique-france/?originalSubdomain=fr"
            target="_blank"
          >
            <img
              class="h-6 cursor-pointer"
              src="/img/linkedin.svg"
              alt="logo linkedin"
            />
          </a> -->
          <a
            href="https://www.facebook.com/Secours.Catholique.Caritas.france"
            target="_blank"
            class="mx-3"
          >
            <img
              class="h-6 cursor-pointer"
              src="/img/facebook.svg"
              alt="logo facebook"
            />
          </a>
          <a
            href="https://www.instagram.com/caritasfrance/?hl=fr"
            target="_blank"
            class="mx-3"
          >
            <img
              class="h-6 cursor-pointer"
              src="/img/instagram.svg"
              alt="logo instagram"
            />
          </a>
        </div>
      </div>

      <!-- <Transition name="slide-fade">
        <div
          v-if="!show && endPages"
          @click="show = true"
          class="block md:hidden bg-white p-4 h-16 rounded-l-full absolute -right-4 mt-2"
        >
          <img src="/img/media.svg" class="w-8 m-auto" alt="logo media" />
        </div>
      </Transition>

      <Transition name="slide-fade">
        <div
          v-if="show"
          @click="show = false"
          class="block md:hidden bg-white p-4 h-16 rounded-l-full absolute -right-4 mt-2"
        >
          <div class="flex gap-6 items-end">
            <a href="https://twitter.com/caritasfrance" target="_blank">
              <img
                class="h-5 cursor-pointer"
                src="/img/twitter.svg"
                alt="logo twitter"
              />
            </a>
            <a
              href="https://www.facebook.com/Secours.Catholique.Caritas.france"
              target="_blank"
            >
              <img
                class="h-6 cursor-pointer"
                src="/img/facebook.svg"
                alt="logo facebook"
              />
            </a>
            <a
              href="https://www.instagram.com/caritasfrance/?hl=fr"
              target="_blank"
            >
              <img
                class="h-6 cursor-pointer"
                src="/img/instagram.svg"
                alt="logo instagram"
              />
            </a>
          </div>
        </div>
      </Transition> -->
    </div>

    <RouterView />

    <div class="w-full pb-4 mt-2">
      <div
        class="mt-4 flex flex-col md:flex-row justify-between text-xs md:text-sm text-white"
      >
        <p
          class="block md:hidden mb-6 bg-orange"
          style="z-index: 999"
          v-if="isLogin"
        >
          *Champs obligatoires
        </p>

        <p style="z-index: 999" class="w-fit bg-orange">
          <a
            href="https://www.secours-catholique.org/mentions-legales"
            target="_blank"
            >Mentions légales et Gestion des cookies</a
          >
        </p>

        <hr
          style="z-index: 999"
          class="text-white block my-2 md:hidden w-8 bg-orange"
        />

        <p
          style="z-index: 999"
          class="hidden md:block bg-orange"
          v-if="isLogin"
        >
          *Champs obligatoires
        </p>

        <p class="hidden md:block bg-orange w-fit" style="z-index: 999">
          Secours Catholique &#169; 2022 Tous droits réservés | Made by HOPENING
        </p>

        <p class="block md:hidden bg-orange w-fit" style="z-index: 999">
          Secours Catholique &#169; 2022 Tous droits réservés
        </p>
        <!-- <hr class="text-white block my-2 md:hidden w-8" /> -->
        <p class="block md:hidden bg-orange w-fit" style="z-index: 999">
          Made by HOPENING
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import taskManager from './datas/taskManager'
export default {
  computed: {
    logoClickable() {
      return (
        this.$route.name &&
        (this.$route.name === 'Login' || this.$route.name.includes('End'))
      )
    },
    isLogin() {
      return this.$route.name === 'Login'
    },
    endPages() {
      return this.$route.name && this.$route.name.includes('End2')
    }
  },
  data() {
    return {
      show: false
    }
  },
  methods: {
    logout() {
      taskManager.logout()
    }
  }
}
</script>
