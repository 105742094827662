import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/base.css'
import { defineRule } from 'vee-validate'
import { required, email } from '@vee-validate/rules'
import { configure } from 'vee-validate'
import { localize } from '@vee-validate/i18n'

defineRule('required', required)
defineRule('email', email)

configure({
  // Generates an English message locale generator
  generateMessage: localize('fr', {
    messages: {
      email: 'Le champ doit être un email valide',
      required: 'Le champ est obligatoire'
    }
  })
})
const app = createApp(App)

app.use(router).use(store)
app.mount('#app')
