<template>
  <div
    id="app-grid"
    class="app-grid z-40"
    :style="'grid-template-columns: repeat(' + length + ', minmax(0, 1fr));'"
    @touchmove="noScroll"
    @mousedown="mousedown"
    @mouseup="mouseup"
    @touchstart.passive="mousedown"
    @touchend.passive="touchend"
  >
    <svg :id="id" version="1.1" xmlns="http://www.w3.org/2000/svg"></svg>
    <!-- ! Chaque li = une case (une lettre) -->
    <div
      v-for="(letter, idx) in letters"
      v-bind:key="idx"
      :id="'letter-' + idx"
      :data-index="idx"
      class="grid-letter"
      :class="isFinished ? '' : 'cursor-pointer '"
      @mouseenter="mouseenter"
      @touchmove.passive="touchmove"
    >
      {{ letter }}
    </div>
  </div>
</template>

<script>
import Snap from 'snapsvg-cjs'

export default {
  emits: ['word-match', 'changeOpen'],
  data: function () {
    return {
      selword: '',
      snap: null,
      length: 10,
      draw:
        window.innerWidth < 370
          ? 14
          : window.innerWidth < 640
          ? 16
          : window.innerWidth < 1536
          ? 20
          : 28,
      mouseDown: false,
      p1: null,
      lp1: null,
      line: null,
      p2: null,
      cp2: null,
      lp2: null,
      oldTargetTouch: null
    }
  },
  props: {
    grid: [Array],
    words: [Array],
    letters: [String],
    id: [String],
    isFinished: [Boolean],
    finishedNoTimer: [Boolean]
  },
  name: 'ViewGrid',
  methods: {
    distance2D(x1, y1, x2, y2) {
      let x = x1 - x2
      let y = y1 - y2
      return Math.sqrt(x * x + y * y)
    },
    getSelectedWord(data) {
      let word = ''
      data.indices.forEach((idx) => {
        word += this.letters[idx]
      })
      return word
    },
    getAngle(x1, y1, x2, y2) {
      return (Math.atan2(y2 - y1, x2 - x1) * 180) / Math.PI
    },
    isValidMove(angle) {
      let flag = false
      let validAngles = {
        45: 1,
        '-45': 1,
        90: 1,
        '-90': 1,
        '-135': 1,
        135: 1,
        180: 1,
        0: 1
      }
      if (angle in validAngles) {
        flag = true
      }
      return flag
    },
    lettersBetween(x1, y1, x2, y2) {
      // savoir la lettre que j'ai selectionner
      let dx,
        dy,
        x,
        y,
        tmp,
        rev = false
      let indices = []
      if (x1 > x2) {
        tmp = x1
        x1 = x2
        x2 = tmp
        rev = true
      }
      if (y1 > y2) {
        tmp = y1
        y1 = y2
        y2 = tmp
        rev = true
      }
      dx = x2 - x1
      dy = y2 - y1

      if (x1 != x2) {
        for (x = x1; x <= x2; x++) {
          y = y1 + (dy * (x - x1)) / dx
          indices.push(y * this.length + x)
        }
      } else {
        for (y = y1; y <= y2; y++) {
          x = x1 + (dx * (y - y1)) / dy
          indices.push(y * this.length + x)
        }
      }
      return { indices: indices, reverse: rev }
    },
    noScroll(evt) {
      evt.preventDefault()
    },
    mousedown(evt) {
      //p1 coord top-left de mon premier point cliqué (relativement au parent)
      //lp1  permet avoir coord  dans la grille du premier point
      //line line dessiné surle svg par notre selection
      //p2 coord de chaque poinnt quand on reste cliqué
      //cp2 coord dans la grille de chaque point surligné
      //lp2 coord du point de fin de clique
      this.$emit('changeOpen', false)

      if (!this.isFinished) {
        if (evt.target.classList.contains('grid-letter')) {
          //c'est que je clique actuellement
          this.mouseDown = true

          //Je cherche à quel index est la case
          let index = evt.target.dataset.index
          //coord
          this.p1 = {
            top: evt.target.offsetTop,
            left: evt.target.offsetLeft
          }
          this.oldTargetTouch = evt.target
          // 15 = nom ligne et colonne
          this.lp1 = {
            y: Math.floor(index / this.length),
            x: index % this.length
          }

          // snap svg permet d'intéragir en js avec un svg
          let paper = Snap('#' + this.id)

          //debut du dessin de la line
          this.line = paper.line(
            this.p1.left + this.draw,
            this.p1.top + this.draw,
            this.p1.left + this.draw,
            this.p1.top + this.draw
          )
          this.line.attr({
            class: 'line-1'
          })

          evt.target.classList.add('done')
        }
      }
    },
    mouseenter(evt) {
      if (!this.isFinished) {
        if (this.mouseDown) {
          //si je clique toujours et donc je selectionne, je vais regarder toutes les lettres que je travers
          let index = evt.target.dataset.index
          this.p2 = {
            top: evt.target.offsetTop,
            left: evt.target.offsetLeft
          }

          this.cp2 = {
            y: Math.floor(index / this.length),
            x: index % this.length
          }

          //j'accepte que certain angle de selection
          let angle = this.getAngle(
            this.lp1.x,
            this.lp1.y,
            this.cp2.x,
            this.cp2.y
          )
          if (this.isValidMove(angle)) {
            //je continue a dessiné mon trait
            this.line.attr({
              x2: this.p2.left + this.draw,
              y2: this.p2.top + this.draw
            })

            evt.target.classList.add('done')
          }
        }
      }
      //!au fur est a mesure selon le lettre que je traserve je forme un mot

      //         // let selWord = _this.getSelectedWord(
      //         //   _this.lettersBetween(lp1.x, lp1.y, cp2.x, cp2.y)
      //         // )
      //         // _this.$emit('word-select', selWord)
    },
    touchmove(evt) {
      if (!this.isFinished) {
        let target = document.elementFromPoint(
          evt.touches[0].clientX,
          evt.touches[0].clientY
        )
        if (
          this.mouseDown &&
          target &&
          target.classList.contains('grid-letter') &&
          target != this.oldTargetTouch
        ) {
          //si je clique toujours et donc je selectionne, je vais regarder toutes les lettres que je travers
          this.oldTargetTouch = target
          let index = target.dataset.index

          this.p2 = {
            top: target.offsetTop,
            left: target.offsetLeft
          }

          this.cp2 = {
            y: Math.floor(index / this.length),
            x: index % this.length
          }

          //j'accepte que certain angle de selection
          let angle = this.getAngle(
            this.lp1.x,
            this.lp1.y,
            this.cp2.x,
            this.cp2.y
          )
          if (this.isValidMove(angle)) {
            //je continue a dessiné mon trait
            this.line.attr({
              x2: this.p2.left + this.draw,
              y2: this.p2.top + this.draw
            })

            target.classList.add('done')
          }
        }
      }
      //!au fur est a mesure selon le lettre que je traserve je forme un mot

      //         // let selWord = _this.getSelectedWord(
      //         //   _this.lettersBetween(lp1.x, lp1.y, cp2.x, cp2.y)
      //         // )
      //         // _this.$emit('word-select', selWord)
    },
    mouseup(evt) {
      if (!this.isFinished) {
        if (evt.target.classList.contains('grid-letter')) {
          //A la fin de clique
          this.mouseDown = false
          let index = evt.target.dataset.index

          this.lp2 = {
            y: Math.floor(index / this.length),
            x: index % this.length
          }

          let angle = this.getAngle(
            this.lp1.x,
            this.lp1.y,
            this.lp2.x,
            this.lp2.y
          )

          if (this.isValidMove(angle)) {
            //j'ai le mot final
            let selWord = this.getSelectedWord(
              this.lettersBetween(
                this.lp1.x,
                this.lp1.y,
                this.lp2.x,
                this.lp2.y
              )
            )

            //je regarde s'il existe dans ma liste de mot
            let matchedIndex = this.words.findIndex((word) => {
              return !word.done && word.word.toUpperCase() == selWord
            })

            // si ce n'est pas le cas je delete ma ligne
            if (matchedIndex == -1) {
              this.line.node.remove()
              // $(line.node).fadeOut('slow', function () {
              //   $(this).remove()
              // })
            } else {
              this.$emit('word-match', matchedIndex)
            }

            this.oldTargetTouch == null
            // _this.$emit('word-select', '')
          } else {
            this.line.node.remove()
          }
        }
      }
    },
    touchend() {
      if (!this.isFinished) {
        let target = this.oldTargetTouch
        if (target && target.classList.contains('grid-letter')) {
          //A la fin de clique
          this.mouseDown = false
          let index = target.dataset.index

          this.lp2 = {
            y: Math.floor(index / this.length),
            x: index % this.length
          }

          let angle = this.getAngle(
            this.lp1.x,
            this.lp1.y,
            this.lp2.x,
            this.lp2.y
          )

          if (this.isValidMove(angle)) {
            //j'ai le mot final
            let selWord = this.getSelectedWord(
              this.lettersBetween(
                this.lp1.x,
                this.lp1.y,
                this.lp2.x,
                this.lp2.y
              )
            )

            //je regarde s'il existe dans ma liste de mot
            let matchedIndex = this.words.findIndex((word) => {
              return !word.done && word.word.toUpperCase() == selWord
            })

            // si ce n'est pas le cas je delete ma ligne
            if (matchedIndex == -1) {
              this.line.node.remove()
              // $(line.node).fadeOut('slow', function () {
              //   $(this).remove()
              // })
            } else {
              this.$emit('word-match', matchedIndex)
            }

            this.oldTargetTouch = null
            // _this.$emit('word-select', '')
          } else {
            this.line.node.remove()
          }
        }
      }
    }
    // reset() {
    //   $('#appIllustrations line').remove()
    // }
  },
  mounted() {
    let _this = this

    let viewport = document.body
    viewport.addEventListener('mouseup', function () {
      if (_this.mouseDown) {
        _this.line.node.remove()
      }
      _this.oldTargetTouch = null
    })
    //!si pendant la fin de la selection je suis hors du cadre j'efface la selection

    // console.log(window.innerWidth, _this.draw)
  },
  watch: {
    isFinished(value) {
      if (value) {
        for (let index = 0; index < this.letters.length; index++) {
          if (this.letters[index] == this.letters[index].toUpperCase()) {
            let element = document.getElementById('letter-' + index)

            if (!element.classList.contains('done') && !this.finishedNoTimer) {
              this.p2 = {
                top: element.offsetTop,
                left: element.offsetLeft
              }

              this.cp2 = {
                y: Math.floor(index / this.length),
                x: index % this.length
              }

              let paper = Snap('#' + this.id)
              //je continue a dessiné mon trait
              this.line = paper.line(
                this.p2.left + this.draw,
                this.p2.top + this.draw,
                this.p2.left + this.draw,
                this.p2.top + this.draw
              )

              this.line.attr({
                class: 'line-2'
              })
            }
          }
        }
      }
    }
  }
}
</script>

<style></style>
