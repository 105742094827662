<template>
  <div class="container-app">
    <div class="md:w-3/6 w-11/12 m-auto md:m-0 relative">
      <div class="md:relative top-10">
        <img src="/img/end2.png" alt="famille" class="img-end" />
        <div class="relative bottom-10 flex">
          <div>
            <p class="app-thanks title-app md:ml-10 ml-5">Merci</p>
            <p
              class="text-white uppercase text-xl lg:text-3xl font-bold mt-1 w-full md:w-9/12 title-app md:ml-10 ml-5"
            >
              Vous avez trouvé tous les mots associés aux missions du secours
              catholique !
            </p>
          </div>
          <a
            href="#app-text"
            class="md:hidden p-2 rounded-full h-fit mt-4 mr-7"
            style="background-color: rgba(255, 255, 255, 0.5)"
          >
            <p class="text-2xl text-white text-center">&#8595;</p>
          </a>
        </div>
      </div>
    </div>

    <div id="app-text" class="app-text" style="">
      <p>
        <span class="font-bold"
          >Un grand MERCI à l'ensemble de nos bienfaiteurs</span
        >
        qui ont soutenu en 2021 les missions du Secours Catholique pour
        accompagner près d'1 million de personnes en France et 2,4 millions de
        personnes dans le monde.
      </p>
      <p class="mt-4">
        <span class="font-bold"
          >Notre association agit dans le respect de la DIGNITÉ</span
        >
        de chaque personne pour les aider à sortir de la pauvreté et de
        l'isolement, nouer des liens à travers
        <span class="font-bold">2 400 lieux d'accueil,</span> soutenir les
        familles et enfants les plus démunis, accompagner les personnes en
        recherche de logement (mal logement, sans-abris) et/ou d'emploi (emploi,
        insertion et micro-crédit).
      </p>
    </div>

    <div class="w-fit m-auto mt-4 block md:hidden">
      <p class="text-white w-full text-center font-semibold mb-1">
        Suivez nos actions
      </p>
      <div class="flex bg-white rounded-full p-4 px-6 items-end">
        <a
          href="https://twitter.com/caritasfrance"
          target="_blank"
          class="mx-3"
        >
          <img
            class="h-5 cursor-pointer"
            src="/img/twitter.svg"
            alt="logo twitter"
          />
        </a>
        <!-- <a
            href="https://www.linkedin.com/company/secours-catholique-france/?originalSubdomain=fr"
            target="_blank"
          >
            <img
              class="h-6 cursor-pointer"
              src="/img/linkedin.svg"
              alt="logo linkedin"
            />
          </a> -->
        <a
          href="https://www.facebook.com/Secours.Catholique.Caritas.france"
          target="_blank"
          class="mx-3"
        >
          <img
            class="h-6 cursor-pointer"
            src="/img/facebook.svg"
            alt="logo facebook"
          />
        </a>
        <a
          href="https://www.instagram.com/caritasfrance/?hl=fr"
          target="_blank"
          class="mx-3"
        >
          <img
            class="h-6 cursor-pointer"
            src="/img/instagram.svg"
            alt="logo instagram"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  data: function () {
    return {}
  },
  props: {
    time: [String, Number]
  },
  name: 'AssuranceEnd2',
  methods: {}
}
</script>
