<template>
  <div class="container-app">
    <div class="w-full block md:hidden">
      <p class="uppercase text-2xl text-white font-semibold title-app">
        Aidez-nous à démêler
      </p>
      <div class="flex">
        <div class="xl:flex flex-col">
          <p class="uppercase text-2xl text-white font-semibold title-app">
            les prénoms de
          </p>
          <p class="uppercase text-2xl text-white font-semibold title-app">
            nos bienfaiteurs
          </p>
          <p class="uppercase text-2xl text-white font-semibold title-app">
            pour leur dire
          </p>
        </div>
        <div class="pt-2 ml-4 w-fit h-fit">
          <p
            class="app-thanks title-app !pt-5 !ml-0"
            style="font-size: 3.5rem !important; line-height: 3.5rem !important"
          >
            Merci
          </p>
        </div>
      </div>
    </div>

    <div class="md:w-1/2 sm:w-2/3 lg:1/2 w-full m-auto lg:m-0">
      <img
        class="m-auto z-10 absolute right-0 top-0 md:bottom-0 md:right-auto md:-left-32 w-5/12"
        src="/img/filigrane.svg"
        alt="filigrane"
        style=""
      />
      <img
        class="m-auto relative z-50"
        src="/img/GRILLES_SC.svg"
        alt="grille de jeu"
        style="width: 40rem"
      />
    </div>

    <div
      class="app-game sm:w-2/3 lg:w-1/2 w-full z-50 md:!mt-0 m-auto p-2"
      style="margin-top: -190px"
    >
      <Form @submit="onSubmit" class="flex flex-col mb-5">
        <!-- <div v-if="message !=''" ref="error" class="error text-center"></div>
        <div v-if="message !=''" ref="success" class="sucess text-center">
          {{ message }}
        </div> -->

        <div class="w-full hidden md:block mb-5">
          <p class="uppercase text-5xl text-white font-semibold title-app">
            Aidez-nous à démêler
          </p>
          <div class="flex">
            <div class="xl:flex flex-col">
              <p
                class="block xl:hidden uppercase text-5xl text-white font-semibold title-app"
              >
                les prénoms de nos bienfaiteurs pour leur dire
              </p>
              <p
                class="hidden xl:block uppercase text-5xl text-white font-semibold title-app"
              >
                les prénoms de
              </p>
              <p
                class="hidden xl:block uppercase text-5xl text-white font-semibold title-app"
              >
                nos bienfaiteurs
              </p>
              <p
                class="hidden xl:block uppercase text-5xl text-white font-semibold title-app"
              >
                pour leur dire
              </p>
            </div>
            <div class="pt-2 ml-4 w-fit h-fit">
              <p
                class="app-thanks title-app !pt-5 !ml-0"
                style="font-size: 6rem !important; line-height: 6rem !important"
              >
                Merci
              </p>
            </div>
          </div>
        </div>
        <!-- <a href="#form" class="block md:hidden">
          <img src="/img/arrow.png" class="w-6 m-auto" alt="fleche" />
        </a> -->

        <p
          id="form"
          class="label !font-normal w-2/3 m-auto text-center md:w-full md:text-left mb-3"
        >
          Avant de jouer, dites-nous en plus sur vous :
        </p>

        <div class="flex flex-row flex-wrap mb-3">
          <Field
            v-slot="{ field }"
            :rules="'required'"
            name="civility"
            type="radio"
            :value="'Madame'"
          >
            <label class="label mr-3">
              <input
                type="radio"
                class="cursor-pointer"
                name="civility"
                v-bind="field"
                :value="'Madame'"
              />
              Madame
            </label>
          </Field>
          <Field
            v-slot="{ field }"
            name="civility"
            type="radio"
            :value="'Mademoiselle'"
          >
            <label class="label mr-3">
              <input
                type="radio"
                class="cursor-pointer"
                name="civility"
                v-bind="field"
                :value="'Mademoiselle'"
              />
              Mademoiselle
            </label>
          </Field>
          <Field
            v-slot="{ field }"
            name="civility"
            type="radio"
            :value="'Monsieur'"
          >
            <label class="label">
              <input
                type="radio"
                class="cursor-pointer"
                name="civility"
                v-bind="field"
                :value="'Monsieur'"
              />
              Monsieur
            </label>
          </Field>
          <ErrorMessage class="error" name="civility" />
        </div>

        <div class="flex flex-col md:flex-row w-full mb-3">
          <div class="flex flex-col w-full md:w-1/2 mb-3 md:mr-3">
            <label class="label" for="last_name">Votre nom*</label>
            <Field
              name="last_name"
              type="text"
              class="field"
              placeholder="Nom"
              :rules="'required'"
            />
            <ErrorMessage class="error" name="last_name" />
          </div>

          <div class="flex flex-col w-full md:w-1/2">
            <label class="label" for="first_name">Votre prénom*</label>
            <Field
              name="first_name"
              type="text"
              class="field"
              placeholder="Prénom"
              :rules="'required'"
            />
            <ErrorMessage class="error" name="first_name" />
          </div>
        </div>

        <div class="flex flex-col md:flex-row w-full mb-3">
          <div class="flex flex-col w-full md:w-1/2 mb-3 md:mr-3">
            <label class="label" for="email">Votre courriel*</label>
            <Field
              ref="loginInput"
              name="email"
              type="email"
              class="field"
              placeholder="Couriel"
              :rules="'required'"
            />
            <ErrorMessage class="error" name="email" />
            <div name="email" class="error">
              <p id="error-email"></p>
            </div>
          </div>

          <div class="flex flex-col w-full md:w-1/2">
            <label class="label" for="phone">Votre numéro de téléphone</label>
            <Field
              name="phone"
              type="text"
              class="field"
              placeholder="Numéro de téléphone"
              :rules="validatePhone"
            />
            <ErrorMessage class="error" name="phone" />
          </div>
        </div>

        <div class="w-full flex flex-col justify-center">
          <button class="app-button">Je joue &#x2192;</button>
          <p class="text-xs mt-2 text-white">
            En cliquant sur “Je joue”, vous acceptez de recevoir des
            communications liées à la transmission de patrimoine de la part du
            Secours Catholique Caritas France via les coordonnées collectées
            dans le formulaire. Vos données personnelles collectées resteront
            strictement confidentielles. Elles ne seront ni vendues ni échangées
            conformément à nos mentions légales. Pour en savoir plus sur vos
            droits et la gestion de vos données personnelles
            <a
              class="underline"
              target="_blank"
              href="https://www.secours-catholique.org/mentions-legales"
              >cliquez ici</a
            >.
          </p>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import taskManager from '../datas/taskManager'
import router from '../router'
import { Form, Field, ErrorMessage } from 'vee-validate'

export default {
  components: {
    Field,
    Form,
    ErrorMessage
  },
  name: 'ViewLogin',
  data() {
    return {
      game: window.location.href.includes('legs') ? 'Legs' : 'Assurance-Vie'
    }
  },
  props: {
    message: [String],
  },
  computed: {
    user() {
      return this.$store.state.account.data
    }
  },
  methods: {
    validateEmail(responseText, values) {
      let response = JSON.parse(responseText)

      if (response.autocorrect != '') {
        values.email = response.autocorrect
      }
      if (response.is_valid_format.value == false) {
        let pError = document.getElementById('error-email')
        pError.innerHTML = "Le format de l'email est invalide"
      }
      // else if (
      //   response.is_disposable_email == true
      //   // response.is_mx_foundis_mx_found == false ||
      //   // response.is_smtp_valid == false
      // ) {
      //   let pError = document.getElementById('error-email')
      //   pError.innerHTML = "L'email doit être valide"
      // }
      else if (
        response.deliverability != 'DELIVERABLE' &&
        response.deliverability != 'UNKNOWN'
      ) {
        let pError = document.getElementById('error-email')
        pError.innerHTML = "L'email doit être valide"
      } else {
        let pError = document.getElementById('error-email')
        pError.innerHTML = ''
        taskManager.login(
          values,
          this.sucessLogin,
          this.failedLogin,
          this.$route.query.utm_campaign ?? '',
          this.$route.query.utm_source ?? '',
          this.$route.query.utm_medium ?? '',
          this.$route.query.utm_content ?? '',
          this.game
        )
      }
    },
    onSubmit(values) {

      // if (window.location.href.indexOf('localhost') > -1 || window.location.href.indexOf('digiworks') > -1) {
        let pError = document.getElementById('error-email')
        pError.innerHTML = ''
        taskManager.login(
          values,
          this.sucessLogin,
          this.failedLogin,
          this.$route.query.utm_campaign ?? '',
          this.$route.query.utm_source ?? '',
          this.$route.query.utm_medium ?? '',
          this.$route.query.utm_content ?? '',
          this.game
        )  
        return
      // }
        
      // function httpGetAsync(url, callback) {
      //   var xmlHttp = new XMLHttpRequest()
      //   xmlHttp.onreadystatechange = function () {
      //     if (xmlHttp.readyState === 4 && xmlHttp.status === 200)
      //       if (callback) callback(xmlHttp.responseText, values)
      //   }
      //   xmlHttp.open('GET', url, true) // true for asynchronous
      //   xmlHttp.send(null)
      // }

      // var url =
      //   'https://emailvalidation.abstractapi.com/v1/?api_key=c1f785e9469445da8ae0a976b0411fb6&email=' +
      //   values.email

      // httpGetAsync(url, this.validateEmail)
      
      
    },
    validatePhone(value) {
      // if the field is empty
      if (value) {
        // if the field is not a valid email
        const regex = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/i
        if (!regex.test(value)) {
          return 'Le champ doit être un numéro de téléphone valide'
        }
      }
      //https://regex101.com/r/dsi0Pw/2
      // All is good
      return true
    },
    sucessLogin() {
      if (window.location.href.includes('assurancevie')) {
        router.push({ name: 'AssuranceGame1' })
      } else if (window.location.href.includes('legs')) {
        router.push({ name: 'LegsGame1' })
      } else {
        router.push({ name: 'AssuranceGame1' })
      }
    },
    failedLogin() {
      this.displayError('Une erreur est survenue')
    },
    displayError(message) {
      this.$refs.error.innerText = message
    }
  },
  mounted() {
    var skaze = document.skaze
    skaze.cmd.push(function () {
      skaze.init({ siteIdentifier: 'secourscatholique' })
      skaze.pushEvent({
        name: 'secourscatholique - Landing Page - Jeu Mots Meles',
        properties: {
          lpName: 'jeumotsmeles'
        }
      })
    })
  },
  beforeCreate() {
    document.body.className = 'login'
  }
}
</script>
