<template>
  <div class="container-app">
    <div class="md:w-3/6 w-11/12 m-auto md:m-0 relative">
      <div class="md:relative top-10">
        <img src="/img/end3.png" alt="merci nicole" class="img-end" />
        <div class="relative bottom-10 flex">
          <div>
            <p class="app-thanks title-app md:ml-10 ml-5">Merci</p>
            <p
              class="text-white uppercase text-xl lg:text-3xl font-bold mt-1 w-full md:w-9/12 title-app md:ml-10 ml-5"
            >
              Vous avez terminé le premier jeu en trouvant tous les prénoms de
              nos testateurs !
            </p>
          </div>
          <a
            href="#app-text"
            class="md:hidden p-2 rounded-full h-fit mt-4 mr-7"
            style="background-color: rgba(255, 255, 255, 0.5)"
          >
            <p class="text-2xl text-white text-center">&#8595;</p>
          </a>
        </div>
      </div>
    </div>

    <div id="app-text" class="app-text" style="">
      <p>
        <span class="font-bold">Un grand MERCI</span> à Françoise, Roger, Anne
        et à l'ensemble de nos testateurs ayant choisi de
        <span class="font-bold">léguer leur patrimoine</span> au Secours
        Catholique.
      </p>
      <p class="mt-4">
        Ils soutiennent
        <span class="font-bold">nos actions fraternelles</span> : accompagnement
        de 295000 familles en difficulté, soutien à la scolarisation de 5000
        enfants en difficulté, suivi de 3000 personnes vers l'emploi, ...
      </p>

      <p class="mt-4 text-orange uppercase text-3xl font-bold title-app">
        Sur la terre comme au ciel,
      </p>
      <p class="text-orange uppercase text-3xl font-bold title-app">
        continuons le combat pour la fraternité
      </p>

      <p class="mt-4">
        Le legs de Nicole permet, par exemple, à Antoine de bénéficier d'une
        formation de jardinier-paysagiste et de tourner la page sur 15 années de
        précarité.
      </p>

      <div class="mt-8 flex text-center">
        <router-link
          class="app-button mt-4"
          :to="{
            name: 'LegsGame2',
            params: {
              time1: time
            }
          }"
        >
          Je continue à jouer &#8594;
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  data: function () {
    return {}
  },
  props: {
    time: [String, Number]
  },
  name: 'LegsEnd1',
  methods: {}
}
</script>
