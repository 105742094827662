import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue'
import AssuranceGame1 from '../views/Assurance/Game1.vue'
import AssuranceGame2 from '../views/Assurance/Game2.vue'
import AssuranceEnd1 from '../views/Assurance/End1.vue'
import AssuranceEnd2 from '../views/Assurance/End2.vue'

import LegsGame1 from '../views/Legs/Game1.vue'
import LegsGame2 from '../views/Legs/Game2.vue'
import LegsEnd1 from '../views/Legs/End1.vue'
import LegsEnd2 from '../views/Legs/End2.vue'
import store from '../store/index'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/:utm_campaign?/:utm_source?',
      name: 'Login',
      props: true,
      component: Login
    },
    {
      path: '/assurance/game-1',
      name: 'AssuranceGame1',
      component: AssuranceGame1
    },
    {
      path: '/assurance/end-1',
      name: 'AssuranceEnd1',
      props: true,
      component: AssuranceEnd1
    },
    {
      path: '/assurance/game-2',
      name: 'AssuranceGame2',
      props: true,
      component: AssuranceGame2
    },
    {
      path: '/assurance/end-2',
      name: 'AssuranceEnd2',
      props: true,
      component: AssuranceEnd2
    },

    {
      path: '/legs/game-1',
      name: 'LegsGame1',
      component: LegsGame1
    },
    {
      path: '/legs/end-1',
      name: 'LegsEnd1',
      props: true,
      component: LegsEnd1
    },
    {
      path: '/legs/game-2',
      name: 'LegsGame2',
      props: true,
      component: LegsGame2
    },
    {
      path: '/legs/end-2',
      name: 'LegsEnd2',
      props: true,
      component: LegsEnd2
    }
  ]
})

router.beforeEach((to, from, next) => {
  var skaze = document.skaze
  skaze.cmd.push(function () {
    skaze.init({ siteIdentifier: 'secourscatholique' })
    skaze.pushEvent({
      name: 'secourscatholique - All Page',
      properties: {}
    })
  })

  if (to.name !== 'Login' && !store.getters['account/loggedIn']) {
    next({ name: 'Login' })
  } else if (to.name === 'Login' && store.getters['account/loggedIn']) {
    if (window.location.href.includes('assurancevie')) {
      next({ name: 'AssuranceGame1' })
    } else if (window.location.href.includes('legs')) {
      next({ name: 'LegsGame1' })
    } else {
      next({ name: 'AssuranceGame1' })
    }
  } else {
    next()
  }
})

export default router
