<template>
  <div @click="changeOpen" class="flex flex-col z-50 ml-4 md:ml-0">
    <p
      class="text-white md:text-xl md:mr-2 md:mt-5 md:mb-4 text-center md:text-left"
    >
      Temps restant
    </p>

    <div
      class="flex flex-row mr-6 mb-4 title-app"
      v-if="minutes.toString().length > 1"
    >
      <div
        v-for="(minute, idx) in minutes.toString()"
        v-bind:key="idx"
        class="bg-white text-blue text-2xl md:text-7xl font-semibold rounded-lg w-6 md:w-14 timer-app mx-1"
        style="padding-left: 1px"
      >
        <p class="m-auto w-fit title-app md:mt-3">{{ minute }}</p>
      </div>
      <div class="text-white text-4xl md:text-8xl font-semibold px-1">:</div>
      <div
        v-for="(seconde, idx) in secondes.toString()"
        v-bind:key="idx"
        class="bg-white text-blue text-2xl md:text-7xl font-semibold rounded-lg w-6 md:w-14 timer-app mx-1"
        style="padding-left: 1px"
      >
        <p class="m-auto w-fit title-app md:mt-3">{{ seconde }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    minutes: [Number, String],
    secondes: [Number, String]
  },
  emits: ['changeOpen'],
  methods: {
    changeOpen() {
      this.$emit('changeOpen', false)
    }
  }
}
</script>
