<template>
  <div class="container-app">
    <div
      class="lg:mr-6 md:mb-4 flex flex-row justify-center md:flex-col w-11/12 md:w-1/3"
      style="max-height: 700px"
    >
      <img
        class="absolute right-0 top-32 md:bottom-0 md:right-auto md:-left-32 w-5/12 z-10"
        src="/img/filigrane.svg"
        alt="filigrane"
        style=""
      />
      <p
        @click="open = false"
        class="text-white text-lg md:text-5xl lg:text-6xl mb-4 z-50 mt-4 md:mt-0 title-app"
      >
        Trouvez les prénoms de nos testateurs
      </p>
      <TimerApp
        :open="open"
        :minutes="minutes"
        :secondes="secondes"
        @changeOpen="changeOpen"
      />

      <WordsMobile
        :type="'Prénoms'"
        :isFinished="isFinished"
        :randomWords="randomWords"
        :open="open"
        :words="words"
        :findedWords="findedWords"
        @changeOpen="changeOpen"
      />
    </div>

    <div @click="open = false" class="app-game relative">
      <div class="flex flex-col lg:flex-row">
        <Grid
          :id="'appIllustrations'"
          @changeOpen="changeOpen"
          v-if="letters.length > 0"
          :grid="gridAll"
          :words="words"
          :letters="letters"
          :isFinished="isFinished"
          :finishedNoTimer="finishedNoTimer"
          @word-match="wordMatch"
        ></Grid>

        <div
          class="text-white font-semibold w-full text-center mt-2 block lg:hidden"
        >
          Grille 1/2
        </div>

        <WordsDesktop
          :type="'Prénoms'"
          :isFinished="isFinished"
          :randomWords="randomWords"
          :open="open"
          :words="words"
          :findedWords="findedWords"
          @changeOpen="changeOpen"
        />

        <Transition name="slide-fade">
          <div v-if="isFinished && finishedNoTimer" class="modal">
            <p class="title-app text-3xl font-semibold">BRAVO !</p>
            <p>Vous avez révélé la 1ère valeur du Secours Catholique :</p>
            <p
              class="mt-2 w-fit text-2xl uppercase bg-white rounded-lg text-orange p-2 font-semibold my-1"
            >
              Fraternité
            </p>
            <router-link
              class="w-full text-left mt-1 hover:bg-orange-light bg-orange rounded-full p-1 px-2"
              :to="{
                name: 'LegsEnd1',
                params: {
                  time: time
                }
              }"
            >
              Suivant &#8594;
            </router-link>
          </div>
        </Transition>

        <Transition name="slide-fade">
          <div v-if="isFinished && !finishedNoTimer" class="modal">
            <p>La valeur du Secours Catholique à découvrir était :</p>
            <p
              class="mt-2 mb-2 w-fit text-2xl uppercase bg-white rounded-lg text-orange p-2 font-semibold my-1"
            >
              Fraternité
            </p>

            <a
              class="w-full text-left mt-1 hover:bg-orange-light bg-orange rounded-full p-1 px-4"
              href="/legs/game-1"
            >
              Je retente ma chance &#8594;
            </a>
          </div>
        </Transition>
      </div>

      <div
        class="text-white font-semibold w-2/3 text-center mt-2 hidden lg:block"
      >
        Grille 1/2
      </div>
    </div>
  </div>
  <img
    class="hidden"
    :src="
      'https://ccmperfrt.com/submit/3660/3/5ee470628d13f644d4d57c7add939419/5293b3cfc9598a4cb89bfa5b8e8167c3/' +
      user.email
    "
    alt=""
    width="1"
    height="1"
  />
  <img
    class="hidden"
    :src="
      'https://tr1.b2bever.com/submit/3660/3/5ee470628d13f644d4d57c7add939419/5293b3cfc9598a4cb89bfa5b8e8167c3/' +
      user.email
    "
    alt=""
    width="1"
    height="1"
  />
</template>

<script>
import Grid from '../Grid.vue'
import TimerApp from '../../components/TimerApp.vue'
import WordsMobile from '../../components/WordsMobile.vue'
import WordsDesktop from '../../components/WordsDesktop.vue'
import store from '../../store/index'
import sha256 from 'js-sha256'
import md5 from 'md5'

export default {
  components: {
    Grid,
    WordsMobile,
    WordsDesktop,
    TimerApp
  },

  data: function () {
    return {
      open: true,
      selword: '',
      gameOn: false,
      randomWords: [],
      words: [
        {
          word: 'Francoise',
          wordList: 'Françoise',
          done: false
        },
        {
          word: 'Roger',
          done: false
        },
        {
          word: 'Anne',
          done: false
        },
        {
          word: 'Theodore',
          wordList: 'Théodore',
          done: false
        },
        {
          word: 'Eleonore',
          wordList: 'Eléonore',
          done: false
        },
        {
          word: 'Roseline',
          done: false
        },
        {
          word: 'Nicole',
          done: false
        },
        {
          word: 'Igor',
          done: false
        },
        {
          word: 'Therese',
          wordList: 'Thérèse',
          done: false
        },
        {
          word: 'Edouard',
          wordList: 'Édouard',
          done: false
        }
      ],
      findedWords: 0,
      wl: 0,
      gheight: 10,
      gwidth: 10,
      //horizontal vertical ou diagonal
      // wordLayouts: {
      //   H: (x, y, offset) => [x + offset, y],
      //   V: (x, y, offset) => [x, y + offset],
      //   D: (x, y, offset) => [x + offset, y + offset]
      // },
      // isValidPlacement: {
      //   H: (x, y, wl) => x + wl <= this.gwidth,
      //   V: (x, y, wl) => y + wl <= this.gheight,
      //   D: (x, y, wl) => x + wl <= this.gwidth && y + wl <= this.gheight
      // },
      grid: [],
      gridAll: [
        ['F', 'y', 'E', 'D', 'O', 'U', 'A', 'R', 'D', 'R'],
        ['R', 'R', 'p', 'e', 'n', 'e', 'r', 'I', 'E', 'O'],
        ['O', 'h', 'A', 't', 'j', 'd', 'l', 'G', 'L', 'S'],
        ['G', 't', 'h', 'N', 'A', 'p', 'y', 'O', 'E', 'E'],
        ['E', 'z', 'v', 'a', 'C', 'N', 'y', 'R', 'O', 'L'],
        ['R', 'c', 's', 'o', 'z', 'O', 'N', 'b', 'N', 'I'],
        ['d', 'x', 'x', 'j', 'q', 's', 'I', 'E', 'O', 'N'],
        ['a', 'N', 'I', 'C', 'O', 'L', 'E', 'S', 'R', 'E'],
        ['T', 'H', 'E', 'O', 'D', 'O', 'R', 'E', 'E', 'd'],
        ['p', 't', 'g', 'T', 'H', 'E', 'R', 'E', 'S', 'E']
      ],
      letters: '',
      lfills: 'abcdefghijklmnopqrstuvwxyz',
      timeInGame: 0,
      time: 4 * 60,
      timer: null,
      minutes: 0,
      secondes: 0,
      minutesInGame: 0,
      secondesInGame: 0,
      isFinished: false,
      finishedNoTimer: false,
      timeout: null
    }
  },
  name: 'LegsGame1',
  methods: {
    changeOpen(value) {
      this.open = value
    },
    wordMatch(index) {
      clearTimeout(this.timeout)
      this.findedWords++
      this.words[index].done = true
      this.open = true

      this.timeout = setTimeout(() => {
        this.open = false
      }, 2000)

      if (this.findedWords == this.words.length) {
        this.isFinished = true
        this.finishedNoTimer = true
        this.stop()
      }
    },
    initGame() {
      this.gameOn = true
      this.start()
      // ici
      var lttrs = ''

      this.gridAll.forEach((row) => {
        lttrs += row.join('')
      })
      this.letters = lttrs

      let shuffledWords = Object.keys(this.words).sort(
        () => 0.5 - Math.random()
      )

      shuffledWords.forEach((id) => {
        this.randomWords.push(this.words[id])
      })
    },
    start() {
      this.timer = setInterval(() => {
        if (this.time > 0) {
          this.time--
          this.timeInGame++

          let time = this.time / 60
          let minutes = parseInt(time)
          let secondes = Math.round((time - minutes) * 60)

          if (minutes < 10) {
            minutes = '0' + minutes
          }
          if (secondes < 10) {
            secondes = '0' + secondes
          }

          this.minutes = minutes
          this.secondes = secondes

          let timeInGame = this.timeInGame / 60
          let minutesInGame = parseInt(timeInGame)
          let secondesInGame = Math.round((timeInGame - minutesInGame) * 60)

          if (minutesInGame < 10) {
            minutesInGame = '0' + minutesInGame
          }
          if (secondesInGame < 10) {
            secondesInGame = '0' + secondesInGame
          }
          this.minutesInGame = minutesInGame
          this.secondesInGame = secondesInGame
        } else {
          clearInterval(this.timer)
          this.stop()
        }
      }, 1000)
    },
    stop() {
      clearInterval(this.timer)

      store.commit('account/_set', {
        k: 'score1',
        v: this.findedWords + '/' + this.words.length
      })
      store.commit('account/_set', {
        k: 'time1',
        v: this.minutesInGame + ':' + this.secondesInGame
      })

      this.timer = null
      this.isFinished = true
      this.open = true
    }
  },
  computed: {
    user() {
      return this.$store.state.account.data
    }
  },
  mounted() {
    let _this = this
    _this.initGame()

    var skaze = document.skaze
    skaze.cmd.push(function () {
      skaze.init({ siteIdentifier: 'secourscatholique' })
      skaze.pushEvent({
        name: 'secourscatholique - Lead - Jeu Mots Meles',
        properties: {
          leadId: _this.user.id, //replace by value
          leadName: 'jeumotsmeles',
          emailMD5: md5(_this.user.email), //replace by value
          emailSHA256: sha256(_this.user.email), //replace by value
          phoneMD5: md5(_this.user.phone ? _this.user.phone : 'phone'), //replace by value
          phoneSHA256: sha256(_this.user.phone ? _this.user.phone : 'phone') //replace by value
        }
      })
    })
  }
}
</script>

<style></style>
