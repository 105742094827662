<template>
  <div class="hidden md:block ml-10 flex flex-col mt-4 md:mt-0 w-fit">
    <div class="flex mb-2">
      <div
        class="flex justify-center text-center bg-white rounded-full px-4 p-2 pb-2 text-3xl text-blue w-32 font-semibold whitespace-nowrap title-app"
      >
        <p
          class="m-auto w-1/2 text-orange font-bold pt-1"
          style="font-size: 2.75rem !important"
        >
          {{ findedWords }}
        </p>
        <p class="m-auto pt-1 w-1/2">/ {{ words.length }}</p>
      </div>
      <div class="ml-2 mt-2">
        <p class="text-lg font-bold text-white whitespace-nowrap">
          {{ type }}
        </p>
        <p class="text-lg font-bold text-white whitespace-nowrap">trouvés</p>
      </div>
    </div>
    <TransitionGroup name="list">
      <div
        class="rounded-full p-1 px-2 pt-2 text-lg font-bold mt-2 w-fit flex"
        :class="styleWord(word.done)"
        v-for="word in isRandom()"
        v-bind:key="word"
      >
        <p
          class=""
          :class="
            isFinished
              ? 'text-blue font-bold text-2xl relative bottom-1'
              : ' font-bold text-2xl relative bottom-1'
          "
        >
          {{
            word.wordList
              ? word.wordList.charAt(0).toUpperCase()
              : word.word.charAt(0).toUpperCase()
          }}
        </p>
        <p :class="isFinished ? 'text-orange' : ''">
          {{ word.wordList ? word.wordList.slice(1) : word.word.slice(1) }}
        </p>
      </div>
    </TransitionGroup>
  </div>
</template>

<script>
export default {
  props: {
    type: [String],
    open: [Boolean],
    words: [Array],
    randomWords: [Array],
    findedWords: [Number],
    isFinished: [Boolean]
  },
  emits: ['changeOpen'],
  methods: {
    changeOpen(value) {
      this.$emit('changeOpen', value)
    },
    isRandom() {
      if (this.isFinished) {
        return this.words
      }
      return this.randomWords
    },
    styleWord(isDone) {
      if (this.isFinished) {
        return 'bg-white text-orange'
      }
      if (isDone) {
        return 'bg-white text-orange'
      }
      return 'bg-orange-lightest text-blue'
    }
  }
}
</script>
