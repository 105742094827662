<template>
  <div class="container-app">
    <div
      class="lg:mr-6 md:mb-4 flex flex-row justify-center md:flex-col w-11/12 md:w-1/3"
      style="max-height: 700px"
    >
      <img
        class="absolute right-0 top-32 md:bottom-0 md:right-auto md:-left-32 w-5/12 z-10"
        src="/img/filigrane.svg"
        alt="filigrane"
        style=""
      />
      <p
        @click="open = false"
        class="text-white text-lg md:text-5xl lg:text-6xl mb-4 z-50 mt-4 md:mt-0 title-app"
      >
        Trouvez les mots associés aux missions du Secours Catholique
      </p>

      <TimerApp
        :open="open"
        :minutes="minutes"
        :secondes="secondes"
        @changeOpen="changeOpen"
      />

      <WordsMobile
        :type="'Mots'"
        :isFinished="isFinished"
        :randomWords="randomWords"
        :open="open"
        :words="words"
        :findedWords="findedWords"
        @changeOpen="changeOpen"
      />
    </div>

    <div @click="open = false" class="app-game relative">
      <div class="flex flex-col lg:flex-row">
        <Grid
          :id="'appIllustrations2'"
          @changeOpen="changeOpen"
          v-if="letters.length > 0"
          :grid="gridAll"
          :words="words"
          :letters="letters"
          :isFinished="isFinished"
          :finishedNoTimer="finishedNoTimer"
          @word-match="wordMatch"
        ></Grid>

        <div
          class="text-white font-semibold w-full text-center mt-2 block lg:hidden"
        >
          Grille 2/2
        </div>

        <WordsDesktop
          :type="'Mots'"
          :isFinished="isFinished"
          :randomWords="randomWords"
          :open="open"
          :words="words"
          :findedWords="findedWords"
          @changeOpen="changeOpen"
        />

        <Transition name="slide-fade">
          <div v-if="isFinished && finishedNoTimer" class="modal">
            <p class="title-app text-3xl font-semibold">BRAVO !</p>
            <p>Vous avez révélé la 2ème valeur du Secours Catholique :</p>
            <p
              class="mt-2 w-fit text-2xl uppercase bg-white rounded-lg text-orange p-2 font-semibold my-1"
            >
              Dignité
            </p>
            <router-link
              class="w-full text-left mt-1 hover:bg-orange-light bg-orange rounded-full p-1 px-2"
              :to="{
                name: 'AssuranceEnd2'
              }"
            >
              Suivant &#8594;
            </router-link>
          </div>
        </Transition>

        <Transition name="slide-fade">
          <div v-if="isFinished && !finishedNoTimer" class="modal">
            <p>La valeur du Secours Catholique à découvrir était :</p>
            <p
              class="mt-2 w-fit text-2xl uppercase bg-white rounded-lg text-orange p-2 font-semibold my-1"
            >
              Dignité
            </p>
            <router-link
              class="w-fit text-left mt-1 hover:bg-orange-light bg-orange rounded-full p-1 px-3"
              :to="{
                name: 'AssuranceEnd2'
              }"
            >
              Suivant &#8594;
            </router-link>
          </div>
        </Transition>
      </div>

      <div
        class="text-white font-semibold w-2/3 text-center mt-2 hidden lg:block"
      >
        Grille 2/2
      </div>
    </div>
  </div>
</template>

<script>
import apiManager from '../../datas/apiManager'
import Grid from '../Grid.vue'
import TimerApp from '../../components/TimerApp.vue'
import WordsMobile from '../../components/WordsMobile.vue'
import WordsDesktop from '../../components/WordsDesktop.vue'
// import store from '../../store/index'

export default {
  components: {
    Grid,
    WordsMobile,
    WordsDesktop,
    TimerApp
  },

  data: function () {
    return {
      open: true,
      selword: '',
      gameOn: false,
      randomWords: [],
      words: [
        {
          word: 'Donner',
          done: false
        },
        {
          word: 'Inventer',
          done: false
        },
        {
          word: 'Guider',
          done: false
        },
        {
          word: 'Nouer',
          done: false
        },
        {
          word: 'Inserer',
          wordList: 'Insérer',
          done: false
        },
        {
          word: 'Travailler',
          done: false
        },
        {
          word: 'Ecouter',
          wordList: 'Écouter',
          done: false
        }
      ],

      findedWords: 0,
      wl: 0,
      gheight: 10,
      gwidth: 10,
      //horizontal vertical ou diagonal
      // wordLayouts: {
      //   H: (x, y, offset) => [x + offset, y],
      //   V: (x, y, offset) => [x, y + offset],
      //   D: (x, y, offset) => [x + offset, y + offset]
      // },
      // isValidPlacement: {
      //   H: (x, y, wl) => x + wl <= this.gwidth,
      //   V: (x, y, wl) => y + wl <= this.gheight,
      //   D: (x, y, wl) => x + wl <= this.gwidth && y + wl <= this.gheight
      // },
      grid: [],
      gridAll: [
        ['a', 'G', 'I', 'N', 'V', 'E', 'N', 'T', 'E', 'R'],
        ['u', 'U', 'p', 'q', 'D', 'e', 'r', 'c', 'h', 'E'],
        ['b', 'I', 'm', 'I', 'O', 'd', 'l', 'n', 'l', 'C'],
        ['c', 'D', 'h', 'j', 'N', 'p', 'y', 'v', 'y', 'O'],
        ['q', 'E', 'v', 'a', 'N', 'S', 'y', 'e', 'k', 'U'],
        ['k', 'R', 's', 'o', 'E', 'y', 'E', 'b', 'p', 'T'],
        ['l', 'g', 'b', 'j', 'R', 's', 'y', 'R', 't', 'E'],
        ['s', 'm', 'i', 'a', 'f', 'u', 'v', 'k', 'E', 'R'],
        ['N', 'O', 'U', 'E', 'R', 't', 'w', 'j', 'o', 'R'],
        ['T', 'R', 'A', 'V', 'A', 'I', 'L', 'L', 'E', 'R']
      ],

      letters: '',
      lfills: 'abcdefghijklmnopqrstuvwxyz',
      timeInGame: 0,
      time: 4 * 60,
      timer: null,
      minutes: 0,
      secondes: 0,
      minutesInGame: 0,
      secondesInGame: 0,
      isFinished: false,
      finishedNoTimer: false,
      timeout: null
    }
  },
  props: {
    time1: [String, Number]
  },
  name: 'AssuranceGame2',
  methods: {
    changeOpen(value) {
      this.open = value
    },
    wordMatch(index) {
      clearTimeout(this.timeout)
      this.findedWords++
      this.words[index].done = true
      this.open = true

      this.timeout = setTimeout(() => {
        this.open = false
      }, 2000)

      if (this.findedWords == this.words.length) {
        this.isFinished = true
        this.finishedNoTimer = true
        this.stop()
      }
    },
    initGame() {
      this.gameOn = true
      this.start()
      // ici
      var lttrs = ''

      this.gridAll.forEach((row) => {
        lttrs += row.join('')
      })
      this.letters = lttrs

      let shuffledWords = Object.keys(this.words).sort(
        () => 0.5 - Math.random()
      )

      shuffledWords.forEach((id) => {
        this.randomWords.push(this.words[id])
      })
    },
    start() {
      this.timer = setInterval(() => {
        if (this.time > 0) {
          this.time--
          this.timeInGame++

          let time = this.time / 60
          let minutes = parseInt(time)
          let secondes = Math.round((time - minutes) * 60)

          if (minutes < 10) {
            minutes = '0' + minutes
          }
          if (secondes < 10) {
            secondes = '0' + secondes
          }

          this.minutes = minutes
          this.secondes = secondes

          let timeInGame = this.timeInGame / 60
          let minutesInGame = parseInt(timeInGame)
          let secondesInGame = Math.round((timeInGame - minutesInGame) * 60)

          if (minutesInGame < 10) {
            minutesInGame = '0' + minutesInGame
          }
          if (secondesInGame < 10) {
            secondesInGame = '0' + secondesInGame
          }
          this.minutesInGame = minutesInGame
          this.secondesInGame = secondesInGame
        } else {
          clearInterval(this.timer)
          this.stop()
        }
      }, 1000)
    },
    stop() {
      clearInterval(this.timer)
      this.timer = null
      this.isFinished = true
      this.open = true

      let data = {
        user: this.user,
        game: 'Assurance Vie',
        score1: this.$store.state.account.score1
          ? this.$store.state.account.score1
          : 0,
        time1: this.$store.state.account.time1
          ? this.$store.state.account.time1
          : 0,
        score2: this.findedWords + '/' + this.words.length,
        time2: this.minutesInGame + ':' + this.secondesInGame,
        end_date: Date.now()
      }

      apiManager.sendScore(data).then(() => {})
    }
  },
  computed: {
    user() {
      return this.$store.state.account.data
    }
  },
  mounted() {
    this.time = this.time1
    let _this = this
    _this.initGame()
    // console.log(this.$store.state.account.score1)
    // console.log(this.$store.state.account.time1)
  }
}
</script>

<style></style>
